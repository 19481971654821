.scroll-to-top-button {
    position: fixed;
    bottom: -50px;
    right: 30px;
    z-index: 1000;
    background-color: #000000;
    box-shadow: 0px 0px 10px 0px #ac6ffa;
    color: #ac6ffa;
    font-size: 20px;
    border: none;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #5c5a5a;
    animation: slidePopUp 0.5s ease forwards;
    /* Apply the slidePopUp animation */
}

.scroll-to-top-button:hover {
    background-color: #ac6ffa;
    color: #000000;
    border: 1px solid #ac6ffa;
}

.scroll-to-top-button.hidden {
    opacity: 0;
    pointer-events: none;
    animation: slideDown 0.5s ease forwards;
    /* Apply the slideDown animation when hidden */
}

.scroll-to-top-button.visible {
    opacity: 1;
    pointer-events: auto;
}

@keyframes slidePopUp {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    50% {
        transform: translateY(-80px);
        opacity: 0.8;
    }
    100% {
        transform: translateY(-80px);
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(-20px);
        opacity: 0.8;
    }
    100% {
        transform: translateY(0px);
        opacity: 0;
    }
}