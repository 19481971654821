.Property-Details-Main-Container {
    width: 100vw;
    font-family: "Montserrat";
    flex: 2;
    margin-right: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.Property-Details-Main-Container.fade-in {
    opacity: 1;
}

.Property-Details-Container {
    margin-top: 65px;
    margin-left: 130px;
    margin-right: 130px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
}

.Property-Details-Header-Container {
    display: flex;
    flex-direction: row;
}

.Property-Details-Header-Container-Badge {
    background-color: #f2f2f2;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    font-weight: bold;
    font-size: 12px;
}

.Property-Details-Header-Price-Container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f2f2f2;
    border-radius: 25px;
    font-weight: bold;
}

.Property-Details-Main-Details-Container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.Property-Details-Main-Details-Container-Left-Container {
    flex: 2;
    margin-right: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.Property-Details-Main-Details-Container-Left-Container.fade-in {
    opacity: 1;
}

.Property-Details-Main-Details-Container-Right-Container {
    flex: 1;
    border-radius: 20px;
    margin-left: 10px;
    background-image: url("https://i.ibb.co/GWnXsjp/Rectangle-74-1.png");
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width: 900px) {
    .Property-Details-Main-Details-Container {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .Property-Details-Main-Details-Container-Left-Container {
        flex: 2;
        margin-right: 0px;
    }
    .Property-Details-Main-Container {
        width: 100vw;
        font-family: "Montserrat";
    }
    .Property-Details-Container {
        margin-top: 65px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .Property-Details-Main-Details-Container-Right-Container {
        margin-top: 40px;
        width: 100%;
        background-size: cover;
        background-image: url("https://i.ibb.co/MRftk2s/Rectangle-74.png");
        background-repeat: no-repeat;
    }
}