/* subscriptionModal.css */

.subscription-modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    opacity: 0;
    animation: fadeInFromBottom 0.5s ease-out forwards, moveUp 0.5s ease-out forwards;
}

.subscription-modal-container.fade-out {
    animation: fadeOutToBottom 0.5s ease-out forwards, moveDown 0.5s ease-out forwards;
}

@keyframes fadeInFromBottom {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutToBottom {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}


/* Add moveDown animation for moving down when closing */

@keyframes moveDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(20px);
    }
}

.subscription-modal-content {
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: "Plus Jakarta Sans";
    background-color: #131313;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 60px;
    padding-right: 60px;
}

.subscription-modal-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.subscription-modal-header {
    flex: 1;
}

.subscription-modal-header-text {
    color: #ac6ffa;
    font-size: 24px;
    font-weight: bold;
}

.subscription-modal-description {
    margin-top: 40px;
    font-family: Montserrat;
    color: #676767;
}

.subscription-modal-features {
    margin-top: 40px;
    font-family: Montserrat;
    color: #ffffff;
}

.subscription-modal-image {
    flex: 1;
}

.subscription-modal-image img {
    max-width: 100%;
    max-height: 100%;
}

.subscription-modal-buttons {
    display: flex;
    margin-top: 40px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

@media screen and (max-width: 668px) {
    .subscription-modal-content {
        padding-left: 20px;
        padding-right: 20px;
    }
    .subscription-modal-image {
        display: none;
    }
}