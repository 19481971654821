.Home-Navbar-Container {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.Home-Main-Container {
    padding-top: 5px;
    width: 100vw;
    min-height: 100vh;
    background-color: black;
    overflow-x: hidden;
}

.Home-Banner-Container {
    display: flex;
    background-color: black;
    flex-direction: column;
    background-image: url("https://i.ibb.co/tPQtPSL/fadeGrid.png");
    background-repeat: no-repeat;
    background-position: right top;
}