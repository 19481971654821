@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


/* Add the animation class to the element you want to animate */

.animate__fadeInUp {
    animation: fadeInUp 0.5s ease;
}