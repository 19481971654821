/* feature-card.css */

.Feature-Card {
    display: block;
    background-color: #131313;
    border-radius: 20px;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    opacity: 0;
    transform: translateX(60px);
    transition: opacity 0.5s ease, transform 1s ease;
    
}

.Feature-Card:hover {
    cursor: pointer;
    padding-left: 0px;
    padding-right: 0px;
    transform: scale(1.6);
    z-index: 1;
    border-radius: 0px;
    box-shadow: 0px 0px 10px #a66cf1;
    scale: 1.03;
    transition: scale 0.5s ease-in-out;
}

.Feature-Card:hover .icon-container {
    position: relative;
    top: 1px;
    transition: top 0.5s ease;
    background-color: #a66cf1;
    border-radius: 10px;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background: none;
    background-image: url("https://i.ibb.co/mqBNQ2W/Group-437.png");
    background-repeat: no-repeat;
    background-position: center;
    padding: none;
    background-size: 100%;
}

.Feature-Card:hover .icon-background {
    background: linear-gradient(180deg, #131313 0%, #131313 100%);
}

.Feature-Card.fadeIn {
    opacity: 1;
    transform: translateX(0);
}

.Feature-Card .icon-container {
    position: relative;
    top: -50px;
}

.Feature-Card:hover .title-container {
    border-bottom: none;
}

.Feature-Card .icon-wrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.Feature-Card .icon-background {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    background: linear-gradient(180deg, #a66cf1 0%, #6030c7 100%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Feature-Card .icon {
    color: white;
    font-size: 3x;
}

.Feature-Card:hover .icon {
    /* Make the text color transparent */
    margin-top: 10px;
    /* Set the background color */
}

.Feature-Card .title-container {
    margin-top: 36px;
    padding-bottom: 14px;
    border-bottom: 2px solid #ac6ffa;
    text-align: center;
}

.Feature-Card .title {
    color: white;
    font-family: "Plus Jakarta Sans";
    font-size: 20px;
    font-weight: bold;
}

.Feature-Card .description-container {}

.Feature-Card .description {
    padding-right: 20px;
    display: flex;
    align-content: center;
    height: 200px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Feature-Card .description-text {
    font-size: 16px;
    text-align: center;
    color: #727272;
}

.Feature-Card .additional-info {
    text-align: right;
    position: relative;
    top: -100px;
}