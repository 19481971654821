/* TestimonialCard.css */

.TestimonialCard-container {
    background-image: url(https://i.ibb.co/cL7Dkcm/Vector-36.png);
    background-repeat: no-repeat;
    background-position: bottom;
    opacity: 0;
    transform: translateX(-140px);
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.TestimonialCard-container.visible {
    opacity: 1;
    transform: translateX(0);
}

