.Section4 {
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    font-family: "Plus Jakarta Sans";
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.Section4-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-content: center;
}

.Section4-text {
    font-size: 40px;
    color: white;
    font-family: "Montserrat";
    font-weight: bold;
}

.Section4-highlight {
    color: #8a38f5;
}

.Section4-description {
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    color: #9f9f9f;
    text-align: center;
    margin-top: 20px;
}

.Section4-form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.Section4-question {
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
}

.Section4-input-container {
    display: flex;
    width: 100%;
    margin-top: 30px;
}

.Section4-input-label {
    flex: 1;
    color: white;
    font-weight: bold;
}

.Section4-input {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.Section4-contact {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.Section4-contact-label {
    color: white;
    font-weight: bold;
}

.Section4-contact-input {
    width: 100%;
    margin-top: 30px;
}

.Section4-button {
    margin-top: 50px;
}

.Section4-Hoverable-Button {
    padding: 20px;
    border: 1px solid #676767;
    margin-top: 20px;
    font-size: 22px;
}

.Section4-Hoverable-Button:hover {
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 10px 0px #ffffff;
    color: #ffffff;
    transition: 0.6s;
    cursor: pointer;
}

.Section4-Counter-Container {
    display: flex;
    width: 20%;
    height: 40px;
    border: 1px solid white;
    border-radius: 8px;
    flex-direction: row;
    color: white;
    font-weight: bold;
}

.Section4-Counter-Element {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .Section4-Counter-Container {
        display: flex;
        width: 100%;
        height: 40px;
        border: 1px solid white;
        border-radius: 8px;
        flex-direction: row;
        color: white;
        font-weight: bold;
    }
    .Section4-text {
        font-size: 20px;
    }
    .Section4-description {
        font-size: 14px;
    }
    .Section4-question,
    .Section4-contact-label {
        font-size: 16px;
    }
    .Section4-Hoverable-Button {
        padding: 10px;
        font-size: 12px;
    }
}