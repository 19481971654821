/* ReviewCard.css */

@keyframes fadeInFromBottom {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.Review-Card-Container.fade-in {
    animation: fadeInFromBottom 0.5s ease-out;
    animation-fill-mode: forwards;
    /* Keep the final style after animation */
}

.Review-Card-Container {
    width: 350px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    animation: fadeInFromBottom 0.5s ease-out;
    border: 1px solid #201e1e;
    background-color: black;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(83, 71, 71, 0.75);
}

.Review-Card-Header {
    display: flex;
    flex-wrap: nowrap;
}

.Review-Card-Image {
    display: block;
    width: 80px;
    height: 80px;
}

.Review-Card-Image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
}

.Review-Card-Details {
    display: block;
    width: calc(100% - 40px);
    height: 40px;
    margin-left: 10px;
    color: #ffffff;
}

.Review-Card-Details span {
    font-size: 12px;
    color: #3e3636;
}

.Review-Card-Rating {
    width: 220px;
}

.Review-Card-Rating span {
    font-size: 10px;
    color: #ffffff;
}

.Review-Card-Content {
    color: white;
}

@media screen and (max-width: 750px) {
    .Review-Card-Container {
        width: 90%;
    }
}