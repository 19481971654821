/* login.css */


/* General styles */

.register-login-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: black;
}

.register-left-section {
    width: 45vw;
    background-image: url("https://i.ibb.co/tqS7svL/Login-bg.png");
    background-size: cover;
    position: relative;
}

.register-right-section {
    background-size: 50% auto;
    background-position: right bottom;
    /* <------ */
    flex: 1;
    background-image: url("https://i.ibb.co/tPQtPSL/fadeGrid.png");
    width: 100vw;
    background-repeat: no-repeat;
    background-color: black;
    width: 55vw;
    display: flex;
    flex-direction: column;
    position: relative;
}

.register-fade-in {
    opacity: 0;
    animation: fadeInAnimation 1s ease-in forwards;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Title styles */

.register-title {
    font-size: 40px;
    color: white;
    font-weight: 700;
    font-family: "Montserrat";
    padding-left: 57px;
    width: 420px;
    margin-top: 242px;
}


/* Login form styles */

.register-login-form {
    margin-left: 143px;
    margin-top: 32px;
}

.register-title {
    color: white;
    font-size: 42px;
    font-weight: 700;
    font-family: "Montserrat";
}

.register-sub-title {
    color: #9f9f9f;
    font-size: 12px;
    margin-top: 8px;
    font-family: "Plus Jakarta Sans";
}

.register-input-fields {
    margin-top: 32px;
    width: 95%;
    display: flex;
    flex-direction: column;
}

.register-input-fields>div {
    margin-top: 24px;
}


/* Button and link styles */

.register-actionButton {
    margin-top: 48px;
}

.register-forgot-password {
    color: white;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.register-forgot-password .register-forgot-link {
    color: var(--primary-color);
}


/* Additional elements */

.register-background-dots {
    position: absolute;
    margin-top: 40px;
    right: 5%;
}

.register-fade-grid {
    display: none;
    /* position: absolute; */
    z-index: 0;
    bottom: 0;
    right: 0;
}

.register-actionButton:hover {
    cursor: pointer;
}

@media screen and (max-width: 960px) {
    .register-form {
        margin-left: 43px;
        margin-top: 32px;
    }
    .register-title {
        color: white;
        font-size: 34px;
        font-weight: 700;
        font-family: "Montserrat";
    }
    .register-title {
        font-size: 40px;
        color: white;
        font-weight: 700;
        font-family: "Montserrat";
        padding-left: 17px;
        width: 420px;
        margin-top: 242px;
    }
}

@media screen and (max-width: 768px) {
    .register-left-section {
        display: none;
    }
    .register-right-section {
        width: 100vw;
    }
}