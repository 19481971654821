.input-range__slider {
    background-color: #222;
}

.input-range__track--active {
    background-color: #AC6FFA;
}

.price-range-container {
    margin-top: 30px;
    max-width: 300px;
    margin-bottom: 60px;

  }
  
  .price-range-text{
    font-size: 20px;
    font-weight: 500;
    color: white;
    margin-bottom: 40px;
    font-family: 'Montserrat', sans-serif;
  }

  .price-range-amenities{
    font-size: 20px;
    font-weight: 500;
    color: white;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  .amenities-button {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 30px;
    color: #fff;
    padding: 4px 20px;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .amenities-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .amenities-button.selected {
    background-color: #AC6FFA; 
  }
  .amenities-text {
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding-top: 4px;
  }