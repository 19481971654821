.below-search {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.filter {
    width: 27%;
    margin-top: 20px;
}

.rooms {
    width: 70%;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .below-search {
        flex-direction: column;  
    }

    .filter, .rooms {
        width: 100%;  
        margin-top: 20px;
    }
}
