.Home-Background-Container {
    display: flex;
    flex-direction: column;
    height: 35vh;
    background-image: url(https://i.ibb.co/zQzKyyg/landing-page-bg.png);
    background-size: cover;
    justify-content: center;
    background-repeat: no-repeat;
    align-items: center;
    filter: brightness(0.9);
}

.Home-Background-Search-Input {
    width: 70vw;
    border-radius: 30px;
    border: 0px solid;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    font-family: Montserrat;
}

.Home-Background-Typing-Effect {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}

.Home-Background-Search-Input-Container {
    font-family: Montserrat;
    margin-top: 33px;
    display: flex;
    height: 56px;
    flex-direction: row;
    width: 100vw;
    justify-content: center;
    position:relative;
    right: -180px;
}

.dropdown-search {
    font-family: Montserrat;
    display: flex;
    height: 56px;
    flex-direction: row;
    width: 100vw;
    justify-content: center;
}

.Home-Background-Search-Input {
    background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, #AC6FFA 100%)',
}

.dropdown-search-span {
    color: black;
}

.dropdown-search-div {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    left: -130px
}


@media screen and (max-width: 768px) {

    .Home-Background-Search-Input-Container {
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: center;
        position: relative;
        right: 4px;


    }

    .Home-Background-Search-Input {
        background: white;
    }

    .dropdown-search {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        right: -400px;
        margin-top: 20px;
    }

    .dropdown-search-span {
        color: white;
    }

    .dropdown-search-div {
        padding-left: 20px;
        padding-right: 20px;
        left: -170px;

    }

}