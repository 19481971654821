.HomeBanner-container {
    display: flex;
    margin: 90px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 2s forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.HomeBanner-image {
    display: block;
    margin-right: 40px;
}

.HomeBanner-image img {
    width: 254px;
    height: 254px;
}

/* .HomeBanner-text {
    margin: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
} */

.HomeBanner-title {
    font-size: 30px;
    color: white;
    font-family: Montserrat;
}

.HomeBanner-titleAccent {
    color: #8a38f5;
}


.HomeBanner-infoBlock {
    display: flex;
    margin-left: 50px;
}

.HomeBanner-count {
    color: #8a38f5;
    font-size: 40px;
}

.HomeBanner-infoText {
    font-size: 16px;
    color: white;
    font-family: Montserrat;
}

.HomeBanner-description {
    margin-top: 40px;
    display: block;
    width: 20vw;
}

.HomeBanner-descriptionText {
    font-size: 12px;
    color: #676767;
    font-family: "Plus Jakarta Sans";
}

@media screen and (max-width: 768px) {
    .HomeBanner-title {
        text-align: center;
        font-size: 16px;
    }
    .HomeBanner-titleAccent {
        text-align: center;
        font-size: 16px;
    }
    .HomeBanner-info {
        display: block;
        margin: 30px;
        padding: 20px;
        text-align: center;
    }
    .HomeBanner-description {
        text-align: center;
        display: flex;
        width: 100vw;
        justify-content: center;
        padding: 10px;
        padding-left: 30px;
    }
}