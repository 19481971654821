@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.super-container {
    border-width: 2px;
    background-color: #101010;
    border-radius: 14px;
    animation: fadeInUp 0.5s ease-in-out;
}

.main-container {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.small-image-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.main-container img {
    border-radius: 10px;
    height: 250px;
    width: 430px;
}

.small-image-container img {
    border-radius: 8px;
    height: 50px;
    width: 70px;
}

.container {
    padding-top: 32px;
    padding-left: 20px;
}

.image-container {
    padding: 20px;
    border-radius: 12px;
    cursor: pointer;
}

.image-container img {
    border-radius: 10px;
    max-width: 100%;
    height: auto;
}

.heading1 {
    color: #fff;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 24px;
}

.address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;
}

.addressText {
    color: #676767;
    font-family: "Montserrat";
    font-size: 14px;
    margin: 0;
}

.starRating {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding-top: 16px;
}

.starIcon {
    color: #ac6ffa;
}

.ratingText {
    color: #fff;
    font-family: "Montserrat";
    font-size: 16px;
    margin: 0;
}

.reviewCount {
    color: #9f9f9f;
    font-family: "Montserrat";
    font-size: 14px;
    margin: 0;
}

.price {
    color: #fff;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 22px;
    padding-top: 16px;
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 14px;
    padding-top: 20px;
}

.customButton {
    font-size: 16px;
    border-radius: 20px;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-width: 1px;
    color: #9f9f9f;
    font-weight: 600;
    background-color: transparent;
    transition-duration: 0.4s;
}

.customButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.bookNowButton {
    background-color: #ac6ffa;
    color: black;
    border-color: #ac6ffa;
}

.bookNowButton:hover {
    background-color: #7f4dbf;
}

@media (max-width: 768px) {
    .super-container {
        padding-bottom: 40px;
    }
    .main-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .small-image-container {
        display: flex;
        flex-direction: row;
        gap: 4px;
        padding-left: 10px;
    }
    .image-container {
        width: 430px;
        height: 250px;
    }
    .main-container img {
        height: auto;
        width: 100%;
    }
    .small-image-container img {
        height: auto;
        width: 60px;
    }
}