/* Add the navbar-container class as a wrapper for your Navbar component */

.navbar-container {
    display: flex;
    width: 100vw;
    background-color: black;
    height: 75px;
    font-family: "Montserrat";
    border-bottom: 1px solid #676767;
}


/* Add the following CSS for dropdown effect */


/* Add a new class for the dropdown container */

.dropdown-container {
    position: fixed;
    left: 88.5vw;
    display: none;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


/* Show the dropdown when the parent is being hovered */

.navbar-signup:hover .dropdown-container {
    display: flex;
    background-color: black;
    top: 64px;
}


/* Add some styling to the dropdown items */

.dropdown-item {
    margin-top: 23px;
    text-align: center;
    padding: 8px 16px;
    color: white;
    font-family: "Montserrat";
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}


/* Change the background color on hover */

.dropdown-item:hover {
    background-color: #333;
}

.navbar-fade-in {
    opacity: 0;
    animation: fadeInAnimation 0.6s ease-in forwards;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Add the navbar-logo class for styling the logo container */

.navbar-logo {
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    margin-top: 6px;
    margin-left: 32px;
}

.email-link {
    text-decoration: none;
    /* Removes underline */
    color: inherit;
    /* Keeping the color as inherited for default look */
}


/* Add the navbar-section class for styling each section in the middle */

.navbar-section {
    flex: 4;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}


/* Add the navbar-item class for styling each individual item in the middle section */

.navbar-item {
    display: flex;
    align-items: center;
    border-left: 1px solid #676767;
    border-right: 1px solid #676767;
    padding-left: 14px;
    padding-right: 14px;
}


/* Add the navbar-contact class for styling the contact section */

.navbar-contact {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Montserrat";
}


/* Add the navbar-login and navbar-signup classes for styling login and signup buttons */

.navbar-login {
    color: white;
    font-family: "Montserrat";
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 50px;
}

.navbar-title {
    color: white;
    font-size: 14px;
    font-family: "Montserrat";
}

.navbar-subtitle {
    color: #676767;
    font-size: 12px;
}

.navbar-signup {
    color: #ac6ffa;
    padding: 8px 16px;
    font-family: "Montserrat";
    border-radius: 50px;
}

.navbar-login:hover {
    border-radius: 50px;
    font-family: "Montserrat";
    /* add a circular border and glow effect on hover */
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 10px 0px #ffffff;
    color: #ffffff;
    transition: 0.6s;
    cursor: pointer;
}

.navbar-signup:hover {
    border-radius: 50px;
    font-family: "Montserrat";
    /* add a circular border and glow effect on hover */
    border: 1px solid #ac6ffa;
    box-shadow: 0px 0px 10px 0px #ac6ffa;
    color: #ac6ffa;
    transition: 0.6s;
    cursor: pointer;
}

.Nav-Option {
    padding-right: 18px;
    padding-left: 18px;
}

.Nav-Option :hover {
    cursor: pointer;
}

.navbar-burger {
    display: none;
}

.navbar-dropdown {
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out;
    position: absolute;
    top: 75px;
    z-index: 10000;
}

.global-hover-effect {
    cursor: pointer;
    font-family: "Montserrat";
    /* add a circular border and glow effect on hover */
    color: #ac6ffa;
    transition: 0.6s;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 50px;
    background-color: black;
}

.global-hover-effect:hover {
    border-radius: 50px;
    font-family: "Montserrat";
    /* add a circular border and glow effect on hover */
    border: 1px solid #ac6ffa;
    box-shadow: 0px 0px 10px 0px #ac6ffa;
    color: #ac6ffa;
    transition: 0.6s;
    cursor: pointer;
}

@media screen and (max-width: 978px) {
    .navbar-section {
        display: none;
    }
    .navbar-signup {
        display: none;
    }
    .navbar-burger {
        display: block;
        margin-left: 52px;
    }
    .navbar-burger:hover {
        cursor: pointer;
        font-family: "Montserrat";
        /* add a circular border and glow effect on hover */
        box-shadow: 0px 0px 10px 0px #ac6ffa;
        color: #ac6ffa;
        transition: 0.6s;
        cursor: pointer;
    }
}