.Section5 {
    width: 100%;
    height: 100%;
    opacity: 0;
    /* Start with opacity set to 0 */
    animation: fadeIn 1s ease-in-out forwards;
    /* Animation properties */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.Section5-description {
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    color: #9f9f9f;
    text-align: center;
    margin-top: 20px;
}

.Section5-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-content: center;
}

.Section5-text {
    font-size: 40px;
    color: white;
    font-family: "Montserrat";
    font-weight: bold;
}

.Section5-highlight {
    color: #8a38f5;
}

.Section5-Hoverable-Button {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    border: 1px solid #676767;
    margin-top: 20px;
    font-size: 12px;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 12px;
    text-align: center;
}

.Section5-Hoverable-Button:hover {
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 10px 0px #ffffff;
    color: #ffffff;
    transition: 0.6s;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Section5-text {
        font-size: 20px;
    }
    .Section5-Hoverable-Button {
        padding-top: 3px;
        padding-bottom: 10px;
        margin-top: 20px;
        font-size: 12px;
    }
}