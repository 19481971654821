/* RoomCard.css */

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.Room-Card {
    width: 390px;
    background-color: #131212;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 2px 0px #181919;
    display: flex;
    flex-direction: column;
    animation: fadeInUp 0.5s ease-in-out;
    /* Apply animation to each card */
}

.Room-Card-header {
    border-bottom: 1px solid #181919;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.Room-Card-image {
    height: 250px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.Room-Card-image img {
    width: 100%;
    max-height: 100%;
    border-radius: 4px;
}

.Room-Card-content {
    padding-top: 20px;
    padding: 10px;
    color: white;
    font-family: Montserrat;
}

.Room-Card-content-name {
    font-size: 15px;
    font-weight: bold;
}

.Room-Card-content-address {
    font-size: 13px;
    padding-top: 10px;
}

.Room-Card-content-address span {
    font-size: 13px;
}

.Room-Card-footer {
    margin: 10px;
    display: flex;
}

.Room-Card-footer-left {
    flex: 1;
    display: flex;
    align-items: center;
}

.Room-Card-price {
    color: #ac6ffa;
    font-weight: bold;
    font-family: Montserrat;
}

.Room-Card-footer-right {
    flex: 1;
}

.Room-Card-view-more {
    background-color: #2E3646;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    /* Add transition for smooth effect */
}

.Room-Card-view-more:hover {
    background-color: #394855;
    /* Change color on hover */
    box-shadow: 0 0 5px #394855
    /* Add glow effect on hover */
}

.Room-Card-view-more span {
    color: white;
}

.Room-Card-image img {
    width: 100%;
    max-height: 100%;
    border-radius: 4px;
    object-fit: cover;
    /* Add this line */
}